import { FlyoutModal } from "@naf/teamscheme";
import { type ReactNode, useCallback } from "react";
import { createContext, useState } from "react";

export interface IInputDescriptionModalContext {
  open(content: ReactNode): void;
}

export const InputDescriptionModalContext =
  createContext<IInputDescriptionModalContext | null>(null);

export function InputDescriptionModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  const open = useCallback((content: ReactNode) => {
    setContent(content);
    setIsOpen(true);
  }, []);

  return (
    <>
      <InputDescriptionModalContext.Provider value={{ open }}>
        {children}
      </InputDescriptionModalContext.Provider>
      <FlyoutModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {content}
      </FlyoutModal>
    </>
  );
}
